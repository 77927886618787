<template>
  <section class="homeDashboard">
    <div class="dateFilter">
      <p class="textDatePicker">Selecione um período</p>
      <div class="dateFilter__datapickerContent">
        <DatePickerCharts/>
        <ButtonShape
          class="buttonShape"
          textButton="Filtrar"
          :bgButtonColor="bgButtonColor"
          @click="getDataApi()"
        />
      </div>
      <span></span>
      <p class="dateFilter__title">Exibindo período de: <strong>{{datepickerRangeNormalStart}}</strong> à <strong>{{datepickerRangeNormalEnd}}</strong></p>
    </div>
    
    <div class="chartMainContainer">
      <LoadingBox v-show="loadingSummary"/>
      <ErrorPages v-show="errorTriggerSummary" :isError="isErrorFlagSummary"/>
      <ChartMain
        v-if="!errorTriggerSummary" v-show="!loadingSummary"
        :successTotal = "totalAPISuccess"
        :failTotal = "totalAPIFail"
        :availableTotal = "totalAPIAvailable"
        :averageTimeTotal = "totalAPIAverage"
        :averageTimeMax = "APIAverageMax"
      />
    </div>
    <div v-show="!masterUserALL" class="chartStatusContainer">
      <LoadingBox v-show="loadingStatusConection"/>
      <ErrorPages v-show="errorTriggerStatusConection" :isError="isErrorFlagStatusConection"/>
      <StatusConection
        v-if="!errorTriggerStatusConection" v-show="!loadingStatusConection"
        :listIfId = "listIfId"
        :listIfName = "listIfName"
        :statusIfs = "statusIfData"
        :statusLabels = "statusLabelData"
      />
    </div>
    <div class="chartResumeContainer">
      <LoadingBox v-show="loadingByAPI"/>
      <ErrorPages v-show="errorTriggerByAPI" :isError="isErrorFlagByAPI"/>
      <div class="boxChartHorizontal" v-if="!errorTriggerByAPI" v-show="!loadingByAPI">
        <ButtonArrowCircle
          class="btnBack"
          @click="goToViewConsumedAPI()"
        />
        <h2>Volumetria Total de APIs</h2>
        <ChartHorizontal
          :totalNumber="APITotalAPIsMax"
          :resumeHorizontal="true"
          :notColorPhase="true"
          :barColor="barColorHoriz"
          :numHorizontal="APITotalAPIs"
          :nameVertical="APITotalAPIsName"
        />
      </div>

      <LoadingBox v-show="loadingByIf"/>
      <ErrorPages v-show="errorTriggerByIf" :isError="isErrorFlagByIf"/>
      <div class="boxChartHorizontal" v-if="!errorTriggerByIf" v-show="!loadingByIf">
        <ButtonArrowCircle
          class="btnBack"
          @click="goToViewIPagAPI()"
        />
        <h2>Volumetria de APIs por<br> Iniciadora de Pagamento</h2>
        <ChartHorizontal
          :totalNumber="APITotalByIfMax"
          :resumeHorizontal="true"
          :barColor="barColorHoriz"
          :notColorPhase="true"
          :numHorizontal="APITotalIPag"
          :nameVertical="APITotalIPagName"
        />
      </div>
    </div>
  </section>
</template>

<script>
import DatePickerCharts from '@/components/DatePickerCharts.vue'
import ButtonShape from '@/components/_molecules/ButtonShape.vue'
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ChartMain from '@/components/homeDashboard/ChartMain.vue'
import StatusConection from '@/components/homeDashboard/StatusConection.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import ChartHorizontal from '@/components/ChartHorizontal.vue'
// import ChartVertical from '@/components/ChartVertical.vue'
import colorsExported from '@/styles/exports.module.scss'
import apiDefault from '@/services/api.js'

export default {
  name: 'HomeDashboard',
  data(){
    return {
      masterUserALL: false,
      loadingSummary: true,
      loadingStatusConection: true,
      loadingByIf: true,
      loadingByAPI: true,
      isErrorFlagSummary: true,
      isErrorFlagStatusConection: true,
      isErrorFlagByIf: true,
      isErrorFlagByAPI: true,
      errorTriggerSummary: false,
      errorTriggerStatusConection: false,
      errorTriggerByIf: false,
      errorTriggerByAPI: false,

      barColorHoriz: [colorsExported.colorChartPurpleDark,
                  colorsExported.colorChartPink,
                  colorsExported.colorChartYellow,
                  colorsExported.colorChartBlueLight,
                  colorsExported.colorChartRed],
      barColorVert: colorsExported.colorChartPurpleDark,
      bgButtonColor: colorsExported.colorPrimary,
      
      setDataAPISummary: [],
      setDataAPIStatus: [],
      getDataAPIConsumedIf: [],
      setDataAPIConsumedAPI: [],

      datepickerFlagRange: '',
      datepickerRangeStart: '',
      datepickerRangeEnd: '',
      datepickerRangeNormalStart: '',
      datepickerRangeNormalEnd: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',

      totalApiIf: [],
      APITotalAPIsGet: [],
      APITotalByIfMax: 0,
      APITotalIPagOthers: 0,
      APITotalAPIsMax: 0,
      APITotalAPIsOthers: 0,

      totalAPISuccess: 0,
      totalAPIFail: 0,
      totalAPIAvailable: 0,
      totalAPIAverage: 0,
      APIAverageMax: [],
      APITotalAPIs: [],
      APITotalAPIsName:[],
      APITotalIPag: [],
      APITotalIPagName: [],

      listIfId: [],
      listIfName:[],
      statusIfData:[],
      statusLabelData:[],

    }
  },
  props: {
    msg: String,
  },
  components: {
    DatePickerCharts,
    ButtonShape,
    ErrorPages,
    LoadingBox,
    ChartMain,
    StatusConection,
    ButtonArrowCircle,
    ChartHorizontal,
    // ChartVertical,   
  },
  mounted() {
    this.setUserData()
  },
  methods: {
    setUserData(){
      this.datepickerFlagRange = localStorage.getItem("datepickerFlagRange");
      this.datepickerRangeStart = localStorage.getItem("datePickerStartFormat");
      this.datepickerRangeEnd = localStorage.getItem("datePickerEndFormat");
      this.datepickerRangeNormalStart = localStorage.getItem("datePickerStartNormal");
      this.datepickerRangeNormalEnd = localStorage.getItem("datePickerEndNormal");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      this.getDataApi();
    },
    setLoadingErrorDefault(){
      this.loadingSummary = true;
      this.isErrorFlagSummary = true;
      this.errorTriggerSummary = false;
      this.loadingByIf = true;
      this.isErrorFlagByIf = true;
      this.errorTriggerByIf = false;
      this.loadingByAPI = true;
      this.isErrorFlagByAPI = true;
      this.errorTriggerByAPI = false;
    },
    getDataApi () {
      this.setLoadingErrorDefault();
      if(this.dataProfileIfName == 'pof'){
        this.masterUserALL = true;
      }else{
        this.masterUserALL = false;
      }
      setTimeout(()=>{
        this.getDataApiSummary();
        this.getDataApiStatusConection();
        this.getDataApiConsumedIf();
        this.getDataApiConsumedAPI();
      }, 2000);
    },
    getDataApiSummary(){
      apiDefault.get(`metrics/digest/summary/${this.datepickerRangeStart}/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.setDataAPISummary = resp.data;
        if(this.setDataAPISummary.availability == null || this.setDataAPISummary.availability == '0'){
          this.loadingSummary = false;
          this.errorTriggerSummary = true;
          this.isErrorFlagSummary = false;
        }else{
          this.errorTriggerSummary = false;
          this.setChartMainData();
        }
      }).catch(error => {
        this.loadingSummary = false;
        this.isErrorFlagSummary = true;
        this.errorTriggerSummary = true;
        console.log('erro: ', error);
      })
    },
    getDataApiStatusConection(){
      apiDefault.get(`/metrics/dcm-status/by-period/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getDataAPIStatusConection = resp.data;
        this.totalApiStatusItem = this.getDataAPIStatusConection.items;
        this.totalApiStatusItem.sort((a, b) => {
          if(a.name < b.name) {
            return -1;
          } else {
            return true;
          }
        });
        this.loadingStatusConection = false;
        if(this.totalApiStatusItem[0] == undefined){
          this.isErrorFlagStatusConection = false;
          this.errorTriggerStatusConection = true;
        }else{
          this.errorTriggerStatusConection = false;
          this.setStatusConectionData();
        }
      }).catch(error => {
        this.isErrorFlagStatusConection = true;
        this.errorTriggerStatusConection = true;
        this.loadingStatusConection = false;
        console.log('erro: ', error)
      })
    },
    getDataApiConsumedIf(){
      apiDefault.get(`metrics/digest/consume-apis-by-payment-initiator/${this.datepickerRangeStart}/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getDataAPIConsumedIf = resp.data;
        this.totalApiIf = this.getDataAPIConsumedIf.paymentInitiators;
        if(this.totalApiIf[0] == undefined){
          this.loadingByIf = false;
          this.isErrorFlagByIf = false;
          this.errorTriggerByIf = true;
        }else{
          this.errorTriggerByIf = false;
          this.setOtherByIFs();
        }
      }).catch(error => {
        this.isErrorFlagByIf = true;
        this.errorTriggerByIf = true;
        this.loadingByIf = false;
        console.log('erro: ', error)
      })
    },
    getDataApiConsumedAPI(){
      apiDefault.get(`metrics/digest/consume-apis-by-api-name/${this.datepickerRangeStart}/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.setDataAPIConsumedAPI = resp.data;
        this.APITotalAPIsGet = this.setDataAPIConsumedAPI.consumeApisByApi;
        if(this.APITotalAPIsGet[0] == undefined){
          this.loadingByAPI = false;
          this.isErrorFlagByAPI = false;
          this.errorTriggerByAPI = true;
        }else{
          this.errorTriggerByAPI = false;
          this.setOtherByAPIs();
        }
      }).catch(error => {
        this.isErrorFlagByAPI = true;
        this.errorTriggerByAPI = true;
        this.loadingByAPI = false;
        console.log('erro: ', error)
      })
    },
    goToViewConsumedAPI(){
			this.$router.push('/volumetria-api');
    },
    goToViewIPagAPI(){
			this.$router.push('/volumetria-api-iniciadora-pagamento');
    },
    setChartMainData(){
      this.loadingSummary = false;
      this.totalAPISuccess = this.setDataAPISummary.totalSuccessfulRequests;
      this.totalAPIFail = this.setDataAPISummary.totalFailedRequests;
      this.totalAPIAvailable = this.setDataAPISummary.availability;
      this.totalAPIAverageRaw = this.setDataAPISummary.averageTimeResponse;
      this.totalAPIAverageSplit = this.totalAPIAverageRaw.toString().split('.');
      this.totalAPIAverage = this.totalAPIAverageSplit[0];
    },
    setStatusConectionData(){
      const statusById = []
      const statusByName = []
      const statusByHTTPstatus = []
      const statusByLabel = []
      this.totalApiStatusItem.forEach((statusByItem) =>{
          statusById.push(statusByItem.id)
          statusByName.push(statusByItem.name)
          statusByHTTPstatus.push(statusByItem.httpStatus)
          statusByLabel.push(statusByItem.status)
          this.listIfId = statusById
          this.listIfName = statusByName
          this.statusIfData = statusByHTTPstatus
          this.statusLabelData = statusByLabel;
        }
      );
    },
    
    //break apart other IF and APIsName
    setOtherByIFs(){
      this.loadingByIf = false;
      this.totalApiIf.sort((a, b) => {
        return b.totalRequest - a.totalRequest;
      });
      let APITotalByIf = 0;
      for(var i = 0; i < this.totalApiIf.length; i++) {
          APITotalByIf += this.totalApiIf[i].totalRequest;
      }
      this.APITotalByIfMax = APITotalByIf
      switch(this.totalApiIf.length){
        case 1:
          this.APITotalIPag = [this.totalApiIf[0].totalRequest];
          this.APITotalIPagName = [this.totalApiIf[0].initiatorPaymentName];
          break;
        case 2:
          this.APITotalIPag = [this.totalApiIf[0].totalRequest,
                            this.totalApiIf[1].totalRequest];
          this.APITotalIPagName = [this.totalApiIf[0].initiatorPaymentName,
                          this.totalApiIf[1].initiatorPaymentName];
          break;
        case 3:
          this.APITotalIPag = [this.totalApiIf[0].totalRequest,
                          this.totalApiIf[1].totalRequest,
                          this.totalApiIf[2].totalRequest];
          this.APITotalIPagName = [this.totalApiIf[0].initiatorPaymentName,
                          this.totalApiIf[1].initiatorPaymentName,
                          this.totalApiIf[2].initiatorPaymentName];
          break;
        case 4:
          this.APITotalIPag = [this.totalApiIf[0].totalRequest,
                          this.totalApiIf[1].totalRequest,
                          this.totalApiIf[2].totalRequest,
                          this.totalApiIf[3].totalRequest];
          this.APITotalIPagName = [this.totalApiIf[0].initiatorPaymentName,
                          this.totalApiIf[1].initiatorPaymentName,
                          this.totalApiIf[2].initiatorPaymentName,
                          this.totalApiIf[3].initiatorPaymentName];
          break;
        case 5:
          this.APITotalIPag = [this.totalApiIf[0].totalRequest,
                          this.totalApiIf[1].totalRequest,
                          this.totalApiIf[2].totalRequest,
                          this.totalApiIf[3].totalRequest,
                          this.totalApiIf[4].totalRequest];
          this.APITotalIPagName = [this.totalApiIf[0].initiatorPaymentName,
                          this.totalApiIf[1].initiatorPaymentName,
                          this.totalApiIf[2].initiatorPaymentName,
                          this.totalApiIf[3].initiatorPaymentName,
                          this.totalApiIf[4].initiatorPaymentName];
          break;
        default:
          this.APITotalIPagOthers = APITotalByIf-
                      (this.totalApiIf[0].totalRequest+
                        this.totalApiIf[1].totalRequest+
                        this.totalApiIf[2].totalRequest+
                        this.totalApiIf[3].totalRequest);
          this.APITotalIPag = [this.totalApiIf[0].totalRequest,
                          this.totalApiIf[1].totalRequest,
                          this.totalApiIf[2].totalRequest,
                          this.totalApiIf[3].totalRequest,
                          this.APITotalIPagOthers];
          this.APITotalIPagName = [this.totalApiIf[0].initiatorPaymentName,
                          this.totalApiIf[1].initiatorPaymentName,
                          this.totalApiIf[2].initiatorPaymentName,
                          this.totalApiIf[3].initiatorPaymentName,
                          'Outros'];
      }                  
    },
    setOtherByAPIs(){
      this.loadingByAPI = false;
      this.APITotalAPIsGet.sort((a, b) => {
        return b.totalRequest - a.totalRequest;
      });
      let APITotalAPIsTotal = 0;
      for(var j = 0; j < this.APITotalAPIsGet.length; j++) {
        APITotalAPIsTotal += this.APITotalAPIsGet[j].totalRequest;
      }
      this.APITotalAPIsMax = APITotalAPIsTotal
      switch(this.APITotalAPIsGet.length){
        case 1:
          this.APITotalAPIs = [this.APITotalAPIsGet[0].totalRequest];
          this.APITotalAPIsName = [this.APITotalAPIsGet[0].apiName];
          break;
        case 2:
          this.APITotalAPIs = [this.APITotalAPIsGet[0].totalRequest,
                            this.APITotalAPIsGet[1].totalRequest];
          this.APITotalAPIsName = [this.APITotalAPIsGet[0].apiName,
                          this.APITotalAPIsGet[1].apiName];
          break;
        case 3:
          this.APITotalAPIs = [this.APITotalAPIsGet[0].totalRequest,
                          this.APITotalAPIsGet[1].totalRequest,
                          this.APITotalAPIsGet[2].totalRequest];
          this.APITotalAPIsName = [this.APITotalAPIsGet[0].apiName,
                          this.APITotalAPIsGet[1].apiName,
                          this.APITotalAPIsGet[2].apiName];
          break;
        case 4:
          this.APITotalAPIs = [this.APITotalAPIsGet[0].totalRequest,
                          this.APITotalAPIsGet[1].totalRequest,
                          this.APITotalAPIsGet[2].totalRequest,
                          this.APITotalAPIsGet[3].totalRequest];
          this.APITotalAPIsName = [this.APITotalAPIsGet[0].apiName,
                          this.APITotalAPIsGet[1].apiName,
                          this.APITotalAPIsGet[2].apiName,
                          this.APITotalAPIsGet[3].apiName];
          break;
        case 5:
          this.APITotalAPIs = [this.APITotalAPIsGet[0].totalRequest,
                          this.APITotalAPIsGet[1].totalRequest,
                          this.APITotalAPIsGet[2].totalRequest,
                          this.APITotalAPIsGet[3].totalRequest,
                          this.APITotalAPIsGet[4].totalRequest];
          this.APITotalAPIsName = [this.APITotalAPIsGet[0].apiName,
                          this.APITotalAPIsGet[1].apiName,
                          this.APITotalAPIsGet[2].apiName,
                          this.APITotalAPIsGet[3].apiName,
                          this.APITotalAPIsGet[4].apiName];
          break;
        default:
          this.APITotalAPIsOthers = APITotalAPIsTotal-
                      (this.APITotalAPIsGet[0].totalRequest+
                        this.APITotalAPIsGet[1].totalRequest+
                        this.APITotalAPIsGet[2].totalRequest+
                        this.APITotalAPIsGet[3].totalRequest);
          this.APITotalAPIs = [this.APITotalAPIsGet[0].totalRequest,
                          this.APITotalAPIsGet[1].totalRequest,
                          this.APITotalAPIsGet[2].totalRequest,
                          this.APITotalAPIsGet[3].totalRequest,
                          this.APITotalAPIsOthers];
          this.APITotalAPIsName = [this.APITotalAPIsGet[0].apiName,
                          this.APITotalAPIsGet[1].apiName,
                          this.APITotalAPIsGet[2].apiName,
                          this.APITotalAPIsGet[3].apiName,
                          'Outros'];
      }                 
    },
  },
}
</script>

<style lang="scss" scoped>
.homeDashboard{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 20px auto 40px auto;
  width: 95%;
  max-width: 1100px;
  .chartMainContainer{
    display: block;
    margin: 4% 0;
    width: 100%;
    @include tablet{
      margin: 4% 2% 4% 0;
      width: 48%;
    }
  }
  .chartStatusContainer{
    display: block;
    margin: 4% 0;
    width: 100%;
    @include tablet{
      margin: 4% 2% 4% 0;
      width: 48%;
    }
  }
  .chartResumeContainer{
    display: flex;
    flex-flow: column;
    margin: 4% 0;
    width: 100%;
    position: relative;
    @include tablet {
      flex-flow: row;
      margin: 0;
    }
  }
  .boxChartHorizontal{
    background-color: white;
    border-radius: $borderRadiusDefault;
    box-shadow: $shadow-default;
    color: $color-gray-darker;
    padding: 20px 60px 20px 20px;
    margin: 4% 0;
    width: 100%;
    position: relative;
    @include tablet{
      margin: 0 2% 4% 0;
      padding: 40px 40px 20px;
    }
    &:nth-child(1){    
      margin: 0 0 8%;
    }
    .btnBack{
      position: absolute;
      right: 20px;
      @include tablet{
        right: 40px;
      }
    }
  }
  .boxChartVertical{
    background-color: white;
    border-radius: $borderRadiusDefault;
    box-shadow: $shadow-default;
    padding: 40px;
    width: 100%;
    position: relative;
    .btnBack{
      position: absolute;
      right: 40px;
    }
  }
}
</style>
