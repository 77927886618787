<template>
	<section>
		<header>
			<div class="headerContainer">
				<div class="headerTitle" @click="goToDefaultView()">
					<i class="headerTitle__logoTecban"><img src="@/assets/images/logo_tecban.svg" alt="logo TecBan"></i>
					<i class="headerTitle__logoTecban"><img src="@/assets/images/logo_portalOpenFinance.svg" alt="logo Portal Open Finance"></i>
				</div>
				<div class="headerLogin">
					<div v-if="isLoged" class="headerLogin__container">
						<select v-show="userMaster" id="selectedIfIds" v-model="selectedIfIds" :value="selectedIfIds">
							<!-- <option :value="getIfId[0].code.toLowerCase()" >{{ getIfId[0].name }}</option> -->
							<option v-for="(getIfIdValue, index) in getIfId"
											:key="index"
											:value="getIfIdValue.code.toLowerCase()">{{ getIfIdValue.name }}</option>
						</select>
						<i class="headerLogin__container--logoIF">
							<img :src="imgIfLogo" :alt="userGroupsName">
						</i>
						<i class="headerLogin__container--notify"
							:class="notificationUpdate ? 'newNotify' : ''"
							@click="gotoNotifications()">
							<img src="@/assets/images/icon_notificationsBell.svg" alt="icone de notificação">
						</i>
						<i class="headerLogin__container--user"
							:class="userMenuTooltip ? 'tooltipOpen' : ''"
							@click="openUserMenu()">
							<img src="@/assets/images/icon_user.svg" alt="icone usuário padrão">
						</i>
						<ul v-if="userMenuTooltip"
								class="headerLogin__container--menuTooltip"
								:class="userMenuTooltip ? '':'zoom'">
							<li @click="gotoProfilePage()">
								<p>
									Dados do Perfil
								</p>
							</li>
							<li @click="logoutProfile()">
								<i>
									<img  src="@/assets/images/icon_logout.svg" alt="sair">
								</i>
								<p>
									Sair
								</p>
							</li>
						</ul>
					</div>
					<div class="headerMenu" @click="clickMenu()">
						<span :class="clickBtnMenu ? 'headerMenu__closeIcon' : 'headerMenu__openIcon'"></span>
					</div>
				</div>
			</div>
		</header>
    <NavMenu v-show="isLoged" :openMenuMobile="clickBtnMenu"/>
	</section>
</template>
<script>
import NavMenu from '@/components/NavMenu.vue'
import logoutUser from '@/globalFunction/logoutUser.js'
import apiDefault from '@/services/api.js'
// PINIA
// import { useUserDataStore } from '@/stores/UserStore'
// import { storeToRefs } from 'pinia';

export default {
	data() {
		return {
			userMenuTooltip: false,
			clickBtnMenu: false,
			isLoged: false,
			userName: '',
			userGroupsName: '',
			userMaster: '',
			userIfIdMaster: false,
			imgIfLogo: '',
			selectedIfIds: [],
			getIfId: [],
			dataUserID: '',
			notificationUpdate: false,
		}
	},
	components: {
		NavMenu,
	},
	watch:{
		$route(){
			this.onChangePathname();
		},
		selectedIfIds(){
			localStorage.setItem("dataUserGroupsName", this.selectedIfIds);
			location.reload();
		}
	},
	created() {
		window.addEventListener("resize", this.onResize);
		this.onChangePathname();
	},
	unmounted() {
		window.removeEventListener("resize", this.onResize);
	},
	methods: {
    goToDefaultView(){
			this.$router.push('/visao-geral');
		},
		gotoNotifications(){
			this.$router.push('/notificacoes');
		},
		onResize() {
				this.clickBtnMenu = false;
		},
		onChangePathname(){
			this.clickBtnMenu = false;
			this.userName = localStorage.getItem("dataUserNameUser");
			if(this.userName){
				this.isLoged = true
				this.setUserProfile();
				
				console.log("usernameddddd", this.userName)
				console.log("sfdfdsfsdsdff",this.getIfId[0])
			}
			else{
				this.isLoged = false
			}
		},
		openUserMenu(){
			if(this.userMenuTooltip == false){
				// abre user menu
				this.userMenuTooltip = true
			}else if(this.userMenuTooltip == true){
				// fecha user menu
				this.userMenuTooltip = false
			}
			return this.userMenuTooltip;
		},
		clickMenu(){
			if(this.clickBtnMenu == false){
				// abre menu
				this.clickBtnMenu = true
			}else if(this.clickBtnMenu == true){
				// fecha menu
				this.clickBtnMenu = false
			}
			return this.clickBtnMenu;
		},
		gotoProfilePage(){
			this.$router.push('/dados-perfil');
			this.userMenuTooltip = false;
		},
		setUserProfile(){
			this.getIfId = JSON.parse(localStorage.getItem("dataUserGroupsNameList")); 
			this.userMaster = localStorage.getItem("dataUserGroupsMaster");
			this.dataUserID = localStorage.getItem("dataUserIdUser");
			this.userGroupsName = localStorage.getItem("dataUserGroupsName");
			this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
			if(this.userGroupsName == 'pof'){
				this.userGroupsName = this.getIfId[0].code.toLowerCase();
				this.goToDefaultView();
			}
			this.imgIfLogo = require('@/assets/images/logos_ifs/logo-'+this.userGroupsName+'.png');
			// PINIA
			// const store = useUserDataStore();
			// const { getUserDataStore, userDataStore, setUserDataStore } = storeToRefs(store);
			// this.userGroupsName = getUserDataStore.value
			// console.log( 'pinia - getUserDataStore', this.userGroupsName, 'userDataStore', userDataStore, 'setUserDataStore', setUserDataStore)
			this.userGroupsName = 'logo'
			this.updateNotification();
		},
		logoutProfile(){
			logoutUser();
		},
		updateNotification(){
			apiDefault.get(`/metrics/messages/status/user/${this.dataUserID}`, {
				headers: {
				ifId: this.dataProfileIfName,
				authorization: 'Bearer '+this.dataUserAccessToken
				}
			}).then(resp => {
				this.notificationUpdate = resp.data;
						console.log(resp.data)
			}).catch(error => {
				console.log('erro: ', error)
			})
		},
	},
}
</script>

<style lang="scss" scoped>
header{
	display: block;
	background: white;
	width: 100%;
	margin: 0 auto;
	padding: 0 0 10px;
	box-shadow: $shadow-icon;
	@include tablet{
		height: 80px;
		padding: 0;
	}
	.headerContainer{
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
		width: 95%;
		max-width: 1100px;
		.headerTitle{
			cursor: pointer;
			display: flex;
			justify-content: flex-start;
			h1{
				font-size: 24px;
				margin: auto 20px auto 0;
				font-weight: 600;
				@include mobileSmall{
					font-size: 14px;
					margin: auto 0;
				}
			}
			i{
				margin: auto 10px;
				img{
					display: block;
					max-height: 80px;
					@include mobileSmall{
						max-height: 50px;
					}
				}
			}
		}
		.headerLogin{
			display: flex;
			justify-content: flex-end;
			margin-left: 20px;
			&__container{
				//descomentar esta linha quando fizer a versão Mobile
				//display: none;
				//@include tablet{
					display: flex;
					justify-content: flex-start;
					position: relative;
					p{
						font-size: 14px;
						font-weight: 600;
						color: $color-tertiary;
						margin: auto 10px auto 0;
						@include tablet{
							margin: auto 10px;
						}
					}
					&--logoIF{
						margin: auto 10px;
						img{
							display: block;
							max-height: 60px;
							max-width: 150px;
							border-radius: $borderRadiusSmall;
							@include mobileSmall{
								max-height: 50px;
							}
						}
					}
					&--user{
						cursor: pointer;
						margin: auto 10px;
						&.tooltipOpen{
							position: relative;
							&:before{
								content: '';
								display: block;
								width: 31px;
								height: 32px;
								border: 2px solid $color-primary;
								border-radius: 50%;
								position: absolute;
							}
						}
					}
					&--menuTooltip{
						display: flex;
						flex-flow: column;
						justify-content: center;
						background-color: white;
						border: 1px solid $color-gray;
						border-radius: $borderRadiusSmall;
						box-shadow: 0 5px 10px $color-gray-light;
						top: 54px;
						right: 15px;
						position: absolute;
						z-index: 999;
						zoom: 0;
						transition: zoom 2s ease;
						&.zoom{
							zoom: 0;
						}
						li{
							cursor: pointer;
							display: flex;
							border-top: 1px solid $color-gray;
							padding: 10px;
							&:nth-child(1){
								border: none;
							}
							p{
								color: $color-gray-darker;
								font-size: 12px;
								margin: auto 60px auto 10px;
								width: max-content;
							}
						}
					}
					&--notify{
						cursor: pointer;
						margin: auto 10px;
						&.newNotify{
							&:before{
								content: '';
								display: block;
								width: 10px;
								height: 10px;
								background-color: $color-success;
								border: 2px solid white;
								border-radius: 50%;
								margin: 4px 0 0 18px;
								position: absolute;
							}
						}
					}
				//}
				select{
					appearance: none;
					background: url('@/assets/images/icon_arrowDown_red.svg') no-repeat $color-gray-lighter;
					background-position: 96% center;
					height: 35px;
					border: 1px solid $color-gray-light;
					border-radius: 4px;
					margin: auto 20px;
					padding: 0 15px;
					font-size: 14px;
					font-weight: 600;
				}
			}
			.headerMenu{
				//deletar esta linha quando fizer a versão Mobile
				display: none;
				// -----
				cursor: pointer;
				margin: auto 0 auto 4%;
				@include tablet{
					display: none;
				}
				&__openIcon, &__closeIcon{
					display: block;
					border-top: 3px solid $color-gray-darker;
					width: 20px;
					height: 20px;
					padding: 4px 0;
					transform: rotate(0deg) translate(0, 0);
					transition: transform 0.5s ease;
					&:after, &:before{
						content:'';
						display: block;
						border-top: 3px solid $color-gray-darker;
						width: 20px;
						height: 4px;
						transform: rotate(0deg) translate(0, 0);
						transition: transform 0.5s ease;
					}
					&:before{
						margin: 0 0 3px;
						opacity: 1;
						transition: opacity 0.5s ease;
					}
				}
				&__closeIcon{
					transform: rotate(45deg) translate(0px, 8px);
					transition: transform 0.5s ease;
					&:after{
						transform: rotate(-90deg) translate(14px, 0px);
						transition: transform 0.5s ease;
					}
					&:before{
						opacity: 0;
						transition: opacity 0.5s ease;
					}
				}
			}
		}
	}
};
</style>
