<template>
  <section class="PaymentInitiator">
    <div class="PaymentInitiator__container">
      <ButtonArrowCircle
        :buttonArrowInvert="true"
        class="btnBack"
        @click="callBackView()"
      />
      <h2>Iniciadoras de Pagamentos</h2>
      <p class="PaymentInitiator__container--description">
        <!-- TEXTO AINDA NÂO FEITO 25/08/2023 -->
      </p>
      <LoadingBox v-show="loadingAPI"/>
      <ErrorPages v-show="errorTrigger" :isError="isErrorFlag"/>
      <div class="PaymentInitiator__selectPeriod" v-if="!errorTrigger" v-show="!loadingAPI">
        <p>
          Selecione uma Iniciadora
        </p>
        <select v-model="selectedPayInitIfId">
          <option disabled value="">Iniciadoras de Pagamentos</option>
          <option v-for="(setPayInitNameIdItemsValue, index) in setPayInitNameIdItems"
                  :key="index"
                  :value="setPayInitNameIdItemsValue.id">{{ setPayInitNameIdItemsValue.name }}</option>
        </select>
      </div>
      <LoadingBox v-show="loadingAPITable"/>
      <ErrorPages v-show="errorTriggerTable" :isError="isErrorFlagTable"/>
      <div ref="payInitTable" 
          class="PaymentInitiator__tableBox"
          :class="offTable ? 'offTable' : ''"
          v-if="!errorTriggerTable"
          v-show="!loadingAPITable">
        <h2 v-show="payInitTableHeader">Iniciadoras de Pagamentos</h2>
        <p v-show="payInitTableHeader" class="PaymentInitiator__container--description">
          <!-- TEXTO AINDA NÂO FEITO 25/08/2023 -->
        </p>
        <table id="payInitTable"
          style="border-right: 1px solid #808080;
                  border-top: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;">
          <thead>
            <tr>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Status</th>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Data</th>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Hora</th>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Descrição do Retorno</th>
              <th style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >Interaction ID</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(setPayInitItemsValue, index) in setPayInitItems" :key="index">
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setPayInitItemsValue.httpStatus }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >&nbsp;{{ setPayInitItemsValue.date.toString() }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;"
              >{{ setPayInitItemsValue.time }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;
                  width: 40%;"
              >{{ setPayInitItemsValue.status }}</td>
              <td style="border-left: 1px solid #808080;
                  border-bottom: 1px solid #808080;
                  font-size: 14px;
                  font-weight: 400;
                  padding: 8px;
                  vertical-align: middle;
                  text-align: center;
                  width: 20%;"
              >{{ setPayInitItemsValue.interactionId }}</td>
            </tr>
          </tbody>
        </table>
        <div class="PaymentInitiator__tableBox--buttons">
          <ButtonShape
            textButton="Exportar .XLSX"
            :bgButtonColor="bgButtonColor"
            @click="exportXLSX()"
          />
          <ButtonShape
            textButton="Exportar .PDF"
            :bgButtonColor="bgButtonColor"
            @click="exportPDF()"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonShape from '@/components/_molecules/ButtonShape.vue'
import ErrorPages from '@/components/_molecules/ErrorPages.vue'
import LoadingBox from '@/components/_molecules/LoadingBox.vue'
import ButtonArrowCircle from '@/components/_molecules/ButtonArrowCircle.vue'
import colorsExported from '@/styles/exports.module.scss'
import apiDefault from '@/services/api.js'
import html2pdf from 'html2pdf.js'
import { goBackView } from '@/globalFunction/globalFunctions.js'

export default {
  name: 'PaymentInitiator',
  data(){
    return {
      isErrorFlag: false,
      errorTrigger: false,
      loadingAPI: true,
      isErrorFlagTable: false,
      errorTriggerTable: false,
      loadingAPITable: true,
      bgButtonColor: colorsExported.colorPrimary,
      
      datepickerRangeStart: '',
      datepickerRangeEnd: '',
      dataProfileIfName: '',
      dataUserAccessToken: '',

      setPayInitTotal: [],
      setPayInitItems: [],
      setPayInitNameIdItems: [],
      selectedPayInitIfId: '',
      
      offTable: false,
      payInitTableHeader: false,
    }
  },
  components: {
    ButtonShape,
    ErrorPages,
    LoadingBox,
    ButtonArrowCircle,
  },
  watch:{
    selectedPayInitIfId(){
      this.getDataPayInitAPI();
    }
  },
  mounted(){
    this.getDataApi();
  },
  methods: {
    callBackView(){
      goBackView();
    },
    getDataApi(){
      this.datepickerRangeStart = localStorage.getItem("datePickerStartFormat");
      this.datepickerRangeEnd = localStorage.getItem("datePickerEndFormat");
      this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
      this.dataUserAccessToken = localStorage.getItem("dataUserAccessToken");
      this.dataIfSelectedId = localStorage.getItem("dataIfSelectedId");
      this.dataIfSelectedName = localStorage.getItem("dataIfSelectedName");
      if(this.dataProfileIfName == 'pof'){
        this.$router.push('/');
      }
      setTimeout(() => {
        this.getDataPayInitNameIdAPI();
        this.getDataPayInitAPI();
      }, '1000');
    },
    // Get Name && ID of the TPPIPs
    getDataPayInitNameIdAPI(){
      apiDefault.get(`/metrics/dcm-status/by-period/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getPayInitNameId = resp.data;
        this.loadingAPI = false;
        if(this.getPayInitNameId.statusCode == 204){
          this.isErrorFlag = false;
          this.errorTrigger = true;
          }else{
          this.errorTrigger = false;
          this.setPayInitNameIdItems = this.getPayInitNameId.items;
        }
      }).catch(error => {
        this.isErrorFlag = true;
        this.errorTrigger = true;
        this.loadingAPI = false;
        console.log('erro: ', error)
      })
    },
    getDataPayInitAPI(){
      this.granularityResults = this.datepickerFlagRange
      apiDefault.get(`/metrics/dcm-status/by-pcm/${this.dataProfileIfName}/by-period/${this.datepickerRangeEnd}`, {
        headers: {
          ifId: this.dataProfileIfName,
          authorization: 'Bearer '+this.dataUserAccessToken
        }
      }).then(resp => {
        this.getPayInit = resp.data;
        this.loadingAPITable = false;
        this.setPayInitItems = this.getPayInit.items;
        if(this.getPayInit == 204){
          this.offTable = false;
          this.isErrorFlagTable = true;
          this.errorTriggerTable = true;
        }else if(this.setPayInitItems == ''){
            this.offTable = false;
            this.isErrorFlagTable = false;
            this.errorTriggerTable = true;
        }else{
          this.offTable = false;
          this.errorTriggerTable = false;
          this.pagination();    
        }
      }).catch(error => {
        console.log('erro: ', error)
        this.loadingAPITable = false;
        if(error.response.status == 400){
          if(this.dataIfSelectedId != ''){
            this.selectedPayInitIfId = this.dataIfSelectedId
            this.selectedPayInitNameId = this.dataIfSelectedName
            localStorage.setItem("dataIfSelectedId", '');
            localStorage.setItem("dataIfSelectedName", '');
          }else{
            this.offTable = true;
          }
        }else{
          this.isErrorFlagTable = true;
          this.errorTriggerTable = true;
        }
      })
    },          
    exportXLSX(){
      const XLSX = require("xlsx");
      var wb = XLSX.utils.table_to_book(document.getElementById('payInitTable'));
      XLSX.writeFile(wb, 'Iniciadoras-de-Pagementos.xlsx');
      // return false;
    },
    exportPDF(){
      this.payInitTableHeader = true;
      html2pdf(this.$refs.payInitTable, {
        margin: 1,
        filename: 'Iniciadoras-de-Pagementos.pdf',
        image: { type: 'jpeg', quality: 0.95 },
        html2canvas: { dpi: 150, letterRendering: true, useCORS: true },
        jsPDF: { unit: 'cm', format: 'a4', orientation: 'landscape' },
        pagebreak: { mode: 'css'}
      })
      setTimeout(() => {      
        this.payInitTableHeader = false;
      }, 1000);
    },
    pagination(){
      let perPage = 5;
      const state = {
        page: 1,
        perPage,
        totalPage: Math.ceil(this.setPayInitItems.length / perPage)
      }
      const controls = {
        next(){
          state.page++
          if(state.page > state.totalPage) {
            state.page--
          }
        },
        prev(){
          state.page--
          if(state.page < 1) {
            state.page++
          }
        },
        goTo(){}
      }
      // console.log(state.page)
      controls.next()
      // console.log(state.page)
      controls.prev()
      // console.log(this.setPayInitItems.length)
    }
  },
}
</script>
<style lang="scss" scoped>
.PaymentInitiator{
  background-color: white;
  border-radius: $borderRadiusDefault;
  box-shadow: $shadow-default;
  color: $color-gray-darker;
  margin: 40px auto 100px auto;
  padding: 40px 0 60px;
  width: 90%;
  max-width: 1100px;
  position: relative;
  &__container{
    padding: 0 40px;
    margin: 0;
    width: 100%;
    h2{
      margin: 0 0 20px 50px;
    }
    &--description{
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      margin: 0;
    }
  }
  &__selectPeriod{
    display: flex;
    border-top: 1px solid $color-gray-lighter;
    border-bottom: 1px solid $color-gray-lighter;
    margin: 20px 0;
    padding: 20px 0;
    width: 100%;
    p{
      font-size: 14px;
      font-weight: bold;
      margin: auto 0;
    }
    select{
      appearance: none;
      background: url('@/assets/images/icon_arrowDown_black.svg') no-repeat 96% center $color-gray-lighter;
      height: 35px;
      border: 1px solid $color-gray-light;
      border-radius: 4px;
      margin: 0 0 0 20px;
      padding: 0 calc(4% + 20px) 0 10px;
      font-size: 14px;
      font-weight: 600;
    }
  }
  &__tableBox{
    display: block;
    overflow-y: scroll;
    max-height: 60vh;
    h2{
      margin: 0 0 10px;
      padding: 0;
    }
    thead, tbody, &--tableTotal{
      border-right: 1px solid $color-gray;
      background-color: $color-gray-lighter;
      width: 100%;
      th, td, .cellX2{
        border-left: 1px solid $color-gray;
        border-bottom: 1px solid $color-gray;
        font-size: 14px;
        font-weight: 400;
        width: 10%;
        margin: 0;
        padding: 8px;
        vertical-align: middle;
        text-align: center;
      }
    }
    thead{
      border-top: 1px solid $color-gray;
      .cellX2{
        width: 20%;
      }
    }
    tbody{
      tr{
        &:nth-child(odd){
          background-color: white;
        }
        &.tableBox--empty{
          background-color: white;
          border: none;
        }
        &.tableBox--total{
          background-color: $color-gray-lighter;
          border-top: 1px solid $color-gray;
        }
      }
    }
    .toCapitalize{
      text-transform: capitalize
    }
    &--buttons{
      display: flex;
      justify-content: right;
      border-top: 1px solid $color-gray-light;
      margin: 50px auto 0;
      padding: 20px 0 0;
      width: 100%;
      .buttonShape{
        margin: auto 0 auto 20px;
      }
    }
  }
  .offTable{
    display: none;
  }
}
</style>