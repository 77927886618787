<template>
  <div class="chartVertical">
    <canvas
      :id="`${'chartVerticalLine' + chartLineNumber}`"
      :ref="IDChartVerticalLine"
      class="chartVertical__chartContent"
      v-show="chartLine">
    </canvas>
    <div v-show="!chartLine" class="chartVertical__chartContent">
      <ul class="chartVertical__chartContent--dataVertical">
        <li>
          <p>{{Math.ceil(totalNumber)}}</p>
        </li>
        <li>
          <p>{{Math.ceil(totalNumber * 0.8)}}</p>
        </li>
        <li>
          <p>{{Math.ceil(totalNumber * 0.6)}}</p>
        </li>
        <li>
          <p>{{Math.ceil(totalNumber * 0.4)}}</p>
        </li>
        <li>
          <p>{{Math.ceil(totalNumber * 0.2)}}</p>
        </li>
        <li>
          <p>0</p>
        </li>
      </ul>
      <div class="chartVertical__chartContent--scroll">
        <div class="chartVertical__chartContent--container">
          <ul class="chartVertical__chartContent--container--barsVertical">
            <li 
              v-for="(numVert, index) in numVertical"
              :key="index"
              :style="{'height' : (numVert * 100 / totalNumber) + '%',
                      'background-color' : (barMultiColor ? barColor[index] : barColor)}">
              <p>{{ milliSec ? labelVertical[index] : numVert}}{{simbolVert}}</p>
            </li>
          </ul>
          <ul class="chartVertical__chartContent--container--dataHorizontal">
            <li v-for="(nameHoriz, index) in nameHorizontal" :key="index">
              <p>{{nameHoriz}}</p>
            </li>
          </ul>
          <div class="chartVertical__chartContent--container--gridLine">
            <ol v-for="n in 5" :key="n" >
              <li v-for="(numVert, index) in numVertical" :key="index">
              </li>
            </ol>
          </div>
        </div>
        <p v-show="numTotal" class="chartTotal" :class="isMetrics ? 'metrics' : ''">
          Total <span>{{numTotal}}{{milliSec ? 'ms' : ''}}</span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from 'chart.js/auto';
export default {
  name: 'ChartVertical',
  data() {
    return {
      dataProfileIfName: '',
      IDChartVerticalLine: '',
      makeChartVerticalLine: '',
    }
  },
  props: {
    chartType: String,
    // chartType: {
    //   type: String,
    //   required: true
    // },
    barColor: {
      type: (String, Object),
      required: true
    },
    barMultiColor: {
      type: Boolean,
      required: true
    },
    totalNumber: {
      type: Number,
      required: true
    },
    numVertical: {
      type: Object,
      required: true
    },
    nameHorizontal: {
      type: Object,
      required: true
    },
    isMetrics: Boolean,
    numTotal: Number,
    labelVertical: Object,
    milliSec: Boolean,
    simbolVert: String,
    chartLine: Boolean,
    chartLineNumber: String,
  },
  
  watch: {
    dataProfileIfName(newDataProfileIfName){
      if( newDataProfileIfName == null ){
        this.$router.push('/');
      }
    },
  },
  mounted(){
    this.dataProfileIfName = localStorage.getItem("dataUserGroupsName");
    this.IDChartVerticalLine = `${'chartVerticalLine' + this.chartLineNumber}`;
    this.chartMakerVertical();
  },
  methods:{
    chartMakerVertical(){
      let makeChartVerticalLine;
      let chartLineOption = {
        type: this.chartType ? this.chartType : 'bar',
        data: {
          labels: this.nameHorizontal,
          datasets: [
            {
              data: this.numVertical,
              backgroundColor: this.barColor,
              borderColor: this.barColor,
              barThickness: 50,
              // maxBarThickness: 50,
              // barPercentage: 0.4,
            }
          ]
        },
        options: {
          plugins: {
            legend: {
              display: false
            },
            tooltip: {
              position: 'average',
              enabled: true,
            }
          },
          scales: {
            y: {
              ticks: {
                maxTicksLimit: 6
              }
            },
          },
        }
      }
      if(makeChartVerticalLine){
        makeChartVerticalLine.destroy();
        makeChartVerticalLine = new Chart(this.IDChartVerticalLine, chartLineOption);
      }else{
        makeChartVerticalLine = new Chart(this.IDChartVerticalLine, chartLineOption);
      }
      
    }
  },
}
</script>

<style lang="scss" scoped>
.chartVertical{
  display: block;
  position: relative;
  &__chartContent{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 30px 0;
    width: 100%;
    height: 100%;
    position: relative;
    &--dataVertical{
      display: flex;
      flex-flow: column;
      height: 250px;
      width: fit-content;
      margin: 0 2% 0 0;
      li{
        height: 50px;
        text-align: right;
        margin: auto 0;
        position: relative;
        &:nth-last-child(1){
          height: 0;
        }
        p{
          color: $color-gray;
          font-size: 12px;
          font-weight: 400;
          margin: 0;
        }
      }
    }
    &--scroll{
      width: 90%;
      padding: 0 0 30px;
      margin: 0 0 -30px;
      overflow-y: hidden;
      &::-webkit-scrollbar {
        background: $color-gray-lighter;
        border-radius: $borderRadiusBig;
        height: 8px;
      }
      &::-webkit-scrollbar-track {
        background-color: $color-gray-lighter;
        border-radius: $borderRadiusBig;
      }
      &::-webkit-scrollbar-thumb {
        background: $color-primary;
        border-radius: $borderRadiusBig;
      }
    }
    &--container{
      display: flex;
      flex-flow: column;
      height: 250px;
      width: fit-content;
      min-width: 99%;
      position: relative;
      &--dataHorizontal{
        display: flex;
        margin-bottom: -50px;
        li{
          width: 100%;
          min-width: 70px;
          padding: 10px 0 0;
          margin: 0 10px;
          p{
            color: $color-gray;
            font-size: 12px;
            font-weight: 400;
            text-align: center;
          }
        }
      }
      &--barsVertical{
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: flex-end;
        height: calc(100% - 30px);
        margin: 30px 0 0;
        z-index: 9;
        li{
          width: 50px;
          margin: 5px 10px 0;
          height: 0;
          transition: height 0.5s;
          p{
            font-size: 12px;
            font-weight: 400;
            margin: -20px -10px 0;
            text-align: center;
          }
        }
      }
      &--gridLine{
        display: flex;
        flex-flow: column;
        height: 100%;
        width: 100%;
        margin: 5px 0 0;
        position: absolute;
        ol{
          display: flex;
          flex-flow: row;
          border-top: 1px solid $color-chart-line;
          border-left: 1px solid $color-chart-line;
          width: 100%;
          height: 50px;
          margin: 0;
          &:nth-last-child(1){
            border-bottom: 1px solid $color-chart-line;
            height: 45px;
          }
          li{
            border-right: 1px solid $color-chart-line;
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
