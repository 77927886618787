<template>
	<nav :class="navIsMobile ? openMenuMobile ? 'navMobile' : 'navMobileClosed' : 'navDesktop'">
		<div ref="navContainer" class="navContainer">
			<div 
				:style="'transform:translateX('+sliderMenu+'px)'"
				ref="iconsContainer"
				class="navContainer__iconsContainer">
				<ButtonMenu
					class="navContainer__iconsContainer--buttonMenu"
					v-for="(iconTitle,index) in iconTitles" :key="index"
					@mouseover="isHoverFlag = true"
					@mouseleave="isHoverFlag = false"
					:isIconMenuMobile="isIconMenuMobile"
					:isBlocked="isBlockedFlag[index]"
					:iconMenuTitle="iconTitle"
					:iconMenuImg="iconImg[index]"
					@click="goToView(index)"
				/>
			</div>
		</div>
		<IconCircleArrow
			class="iconCircleArrowLeft"
			arrowColor="white"
			:bgCircleColor="bgColorCircleLeft"
			:iconRotate="true"
			@click="sliderMenuLeft()"
		/>
		<IconCircleArrow
			class="iconCircleArrowRight"
			arrowColor="white"
			:bgCircleColor="bgColorCircleRight"
			@click="sliderMenuRight()"
		/>
	</nav>
</template>
<script>
import ButtonMenu from '@/components/_molecules/ButtonMenu.vue';
import IconCircleArrow from '@/components/_atoms/IconCircleArrow.vue';
import colorsExported from '@/styles/exports.module.scss'
export default {
	name: 'NavMenu',
	props: {
		openMenuMobile: Boolean,
	},
	data(){
		return {
			navIsMobile: Boolean,
			bgColorCircleLeft: colorsExported.colorGraylighter,
			bgColorCircleRight: colorsExported.colorGraylighter,
			dataUserGroupsMaster: false,
			iconTitles: [
				'Gestão de Usuários',
				'Indicadores / Gráficos',
				'Iniciadoras de Pagamentos',
				'Certificados',
				'LOG',
				'Relatórios',
				'Relatórios TecBan',
				'Painel de comunicação',
				'FAQ / Tutoriais',
				'Biblioteca',
			],
			iconImg:[
				'icon_menuUserCheck',
				'icon_menuStatistic',
				'icon_menuBuilding',
				'icon_menuCertificate',
				'icon_checklist',
				'icon_paste',
				'icon_paste',
				'icon_desktop',
				'icon_help',
				'icon_addressBook',
			],
			linkPageIcon:[
				'/dados-perfil',
				'/indicadores-graficos',
				'/iniciadoras-pagamentos',
				'/certificados',
				'/logs-api',
				'/relatorio-regulatorio',
				'/relatorio-tecban',
				'/home',
				'/',
				'/',
			],
			isBlockedFlag:[],
			isClickedFlag:[
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
			],
			isIconMenuMobile: true,
			windowWidth: window.innerWidth,
			navContainer: '',
			iconsContainer: '',
			sliderMenu: 0,
			sliderSize: 0,
			sliderOdd: 0,
		}
	},
	components: {
		ButtonMenu,
		IconCircleArrow  
	},
	watch:{
		$route(){
			this.onMasterUser();
		}
	},
	mounted() {
		this.iconsContainer = this.$refs.iconsContainer;
		this.navContainer = this.$refs.navContainer;
		this.windowWidth = window.innerWidth;
		this.onResize();
		this.onMasterUser();
		// setTimeout(() => {
		// 	this.onResize();
		// }, 2000);

	},
	created() {
		this.onResize();
		window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
	methods: {
		sliderMenuSize(){
			this.sliderOdd = this.iconsContainer.clientWidth - this.navContainer.clientWidth
			this.sliderSize = 140;
			this.sliderMenu = 0;
			if(this.windowWidth <= 1200 || this.iconsContainer.clientWidth > 1000){
				this.bgColorCircleRight= colorsExported.colorDanger;
			}else{
				this.bgColorCircleRight= colorsExported.colorGraylighter;
			}
			// deletar as duas linhas seguintes ao fazer a versão mobile
			this.navIsMobile = false;
			this.isIconMenuMobile = false
			// -----
			// descomentar ao fazer a versão mobile
			// if(this.windowWidth <= 1024){
			// 	this.navIsMobile = true;
			// 	this.isIconMenuMobile = true
			// }else{
			// 	this.navIsMobile = false;
			// 	this.isIconMenuMobile = false
			// }
		},
		onResize() {
			this.windowWidth = window.innerWidth;
			this.sliderMenuSize();
		},
		sliderMenuRight(){
			if(this.windowWidth <= 1000 || this.iconsContainer.clientWidth > 1000){
				if(this.sliderMenu > - this.sliderOdd){
					this.sliderMenu = this.sliderMenu - this.sliderSize;
					this.bgColorCircleRight= colorsExported.colorDanger;
					this.bgColorCircleLeft= colorsExported.colorDanger;
				}
				if(this.sliderMenu <= -this.sliderOdd){
					this.bgColorCircleRight= colorsExported.colorGraylighter;
				}
			}
		},
		sliderMenuLeft(){
			if(this.sliderMenu < 0){
				this.sliderMenu = this.sliderMenu + this.sliderSize;
				this.bgColorCircleLeft= colorsExported.colorDanger;
				this.bgColorCircleRight= colorsExported.colorDanger;
			}
			if(this.sliderMenu >= 0){
				this.bgColorCircleLeft= colorsExported.colorGraylighter;
			}
		},
		goToView(index){
			this.$router.push(this.linkPageIcon[index]);
			switch(this.linkPageIcon[index]){
				case '/indicadores-graficos': 
					this.isClickedFlag[index] = true;
					break;
				case '/relatorio-regulatorio': 
					this.isClickedFlag[index] = true;
					break;
				case '/notfound': 
					this.isClickedFlag[index] = true;
					break;
				default:
					break;
			}
		},
		onMasterUser(){
			this.dataUserGroupsMaster = localStorage.getItem("dataUserGroupsMaster");
			if(this.dataUserGroupsMaster == 'true'){
				this.isBlockedFlag = [
					false,
					false,
					false,
					false,
					false,
					false,
					false,
					true,
					true,
					true
				];
			}else{
				this.isBlockedFlag = [
					false,
					false,
					false,
					false,
					false,
					false,
					true,
					true,
					true,
					true,
				];
			}
		},
	},
}
</script>
<style lang="scss" scoped>
nav{
	display: none;
	.iconCircleArrowLeft, .iconCircleArrowRight{
		display: none;
	}
}
.navMobile, .navMobileClosed{
	display: block;
	margin: auto;
	padding: 0;
	width: 100%;
	height: 100vh;
	left: 0;
	position: absolute;
	overflow: hidden;
	z-index: 99;
	&:before{
		content: '';
		display: block;
		background-color: rgba(0,0,0,0.5);
		margin: auto;
		padding: 0;
		width: 100%;
		height: 100vh;
		opacity: 1;
		right: 0;
		position: absolute;
		overflow: hidden;
		transition: all 1s ease;
	}
	@include mobileSmall{
		width: 100%;
		max-width: unset;
	}
	.navContainer{
    display: flex;
    justify-content: space-between;
		background-color: white;
		box-shadow: 0 20px 10px 10px $color-gray-darker;
		margin: 0;
		padding: 0;
		width: 320px;
		height: 100vh;
		left: calc(100% - 320px);
		position: relative;
		transition: all 1s ease;
		@include mobileSmall{
			width: 320px;
			max-width: unset;
		}
		&__iconsContainer{
			display: flex;
			justify-content: flex-start;
			margin: 0;
			flex-wrap: wrap;
			width: 100%;
			height: min-content;
			&--buttonMenu{
				cursor: pointer;
				flex-flow: row;
				justify-content: flex-start;
				border-radius: unset;
				margin: 0;
				padding: 0;
				height: fit-content;
				width: 100%;
			}
		}
	}
	.iconCircleArrowLeft, .iconCircleArrowRight{
		display: none;
	}
}
.navMobileClosed{
	z-index: -9;
	&::before{
		display: block;
		right: -100%;
		opacity: 0;
		transition: all 1s ease;
	}
	.navContainer{
		left: calc(100% + 320px);	
		transition: all 1s ease;
	}
}
.navDesktop{
	display: block;
	margin: 0 auto;
	border-bottom: 1px solid $color-gray-lighter;
	padding: 0;
	width: 90%;
	height: 140px;
	max-width: 1100px;
	position: relative;
	.navContainer{
		display: flex;
		justify-content: space-between;
		margin: auto 50px;
		overflow: hidden;
		&__iconsContainer{
			display: flex;
			justify-content: space-between;
			margin: 10px auto;
			transition: transform 0.5s;
			&--buttonMenu{
				cursor: pointer;
				box-shadow: $shadow-icon;
			}
		}
	}
	.iconCircleArrowLeft, .iconCircleArrowRight{
		display: block;
		cursor: pointer;
		top: 50%;
		position: absolute;
		transition: opacity 0.3s;
		transform: translateY(-50%);
		&:hover{
			opacity: 0.8;
		}
	}
	.iconCircleArrowLeft{
		left: 0;
	}
	.iconCircleArrowRight{
		right: 0;
	}
}
</style>
